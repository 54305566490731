#nav {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#logo {
    width: 175px;
    height: auto;
    margin: auto 30px;
}

#navigation {
    list-style-type: none;
    /* removes default padding from ul */
    padding-inline-start: 0px;
    display: flex;
    flex-direction: row;
}

li {
    margin: auto 30px;
}

.menuItem {
    text-decoration: none;
    color: black;
    font-size: 30px;
}

.menuItem:hover {
    color: rgb(110, 110, 110);
    border-bottom: 2px solid rgb(110, 110, 110);
}

#mobileNav {
    display: none;
}

#mobileNavPieces {
    display: flex;
}

#logosmall {
    width: 100px;
    height: auto;
    margin: 5px 0 0 5px;
}

/* div of the burger */
#burger {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 45px;
}

#burger div {
    width: 40px;
    height: 4px;
    margin: 8px;
    transition: all 0.3s ease;
    background-color: black;
    border-radius: 10px;
}

.line1click {
    transform: rotate(-45deg) translate(-10px, 8px);
}

.line2click {
    opacity: 0;
}

.line3click {
    transform: rotate(45deg) translate(-8px, -7px);
}

@media screen and (max-width: 900px) {
    #mobileNav {
        display: block;
    }

    #mobileNavPieces {
        height: 150px;
    }

    #nav {
        display: none;
    }

    .navbar {
        width: 100%;
        height: calc(100vh - 100px);
        top: 150px;
        /* automatically have the navar off the screen to the left side */
        left: -100%;
        opacity: 0;
        position: absolute;
        transition: all 0.5s ease;
        margin-top: 0;
        margin-bottom: 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
    }

    .navbar.active {
        /* bring the navbar in from the left side when the button is clicked */
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10000;
        
    }

    .navlinks {
        font-size: 35px;
        margin: 20px auto;
        text-decoration: none;
        color: black;
    }
}
#contactUsForm {
    display: flex;
    flex-direction: column;
    width: 45%;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
}

.formElement {
    margin-bottom: 10px;
    border-radius: 0px;
    border: 1px solid black;
}

#message {
    height: 125px;
}

#submitBtn {
    border: 1px solid black;
    background-color: white;
    color: black;
    width: 70px;
    padding: 5px;
    font-size: 15px;
}

#submitBtn:hover {
    background-color: rgb(243, 243, 243);
    cursor: pointer;
}

#formSuccess {
    text-align: center;
}
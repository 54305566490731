#performanceHeader {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
#performanceTable {
    text-align: center;
    width: 100%;
    font-size: 1.5vw;
}
td {
    padding: 5px;
}
.performanceButton {
    background-color: rgb(53, 53, 53);
    padding: 2px 10px;
    color: white;
    text-decoration: none;
}

.performanceButton:hover {
    background-color: grey;
}

@media screen and (max-width: 900px) {
    #performanceTable {
        font-size: 2.5vw;
    }
}
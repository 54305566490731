#footer {
    text-align: center;
}

#alcantaraDev {
    color: black;
    text-decoration: none;
}

#alcantaraDev:hover {
    text-decoration: underline;
}
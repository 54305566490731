* {
    font-family: 'Charis SIL', serif;
}

/* Style the page content section */
#pageContent {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(75vh + 10px);
}

@media screen and (max-width: 1500px) {
    #pageContent {
        width: 75%;
    }
}

@media screen and (max-width: 900px) {
    #pageContent {
        width: 90%;
    }
}
#youtubeHeader {
    font-size: 20px;
    text-align: center;
}

#videoContainerAll {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 20px;
    justify-content: center;
    padding-bottom: 60px;
}

.videoContainer {
    position: relative;
    padding-bottom: 56.25%;
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 900px){
    #videoContainerAll {
        grid-template-columns: repeat(1, 100%);
    }
}
#introParagraph {
    margin: 50px auto;
}

#christmas {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    margin: 30px 0;
}

#christmasPoster {
    width: 45%;
    height: auto;
    box-shadow: 0 0 5px 5px rgba(25, 25, 25, 0.2);
    margin-right: 30px;
}

.paragraphBreak {
    height: 20px;
}

#buyTixButton {
    font-size: 20px;
    background: black;
    border: 2px solid black;
    border-radius: 8px;
}

#buyTixButton a {
    text-decoration: none;
    color: white;
}

#buyTixButton:hover {
    border: 2px solid green;
    background: white;
    cursor: pointer;
}

#buyTixButton a:hover {
    color: red;
}

#linkSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
}

.links {
    width: 100%;
    padding-bottom: 66%;
    position: relative;
}

#spotify {
    background-image: url(../../img/home/spotify.jpg);
    background-size: cover;
    background-position: center;
}

#facebook {
    background-image: url(../../img/home/facebook.jpg);
    background-size: cover;
    background-position: center;
}

#itunes {
    background-image: url(../../img/home/itunes.jpg);
    background-size: cover;
    background-position: center;
}

#youtube {
    background-image: url(../../img/home/youtube.jpg);
    background-size: cover;
    background-position: center;
}

/* Overlay for link section */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgba(25, 25, 25, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 45px;
    transition: 0.3s ease;
}

.overlay:hover {
    background-color:rgba(25, 25, 25, 0.8);
}

/* media queries */
@media screen and (max-width: 1800px) {
    #linkSection {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 900px) {
    #linkSection {
        grid-template-columns: 1fr;
    }

    .links:nth-child(2n + 1) {
        margin: 30px 0 30px 0;
    }

    .links {
        width: 100%;
        padding-bottom: 66%;
    }
}

@media screen and (max-width: 715px) {
    #christmas {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
}